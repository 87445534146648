.c-logo-header{
  width: 100px;
  height: 40px;
  fill: white;
}

.c-logo-footer{
  width: 100px;
  height: 40px;

  .primary &{
    fill: $primary;
  }

  .secondary &{
    fill: $secondary;
  }

  .tertiary &{
    fill: $tertiary;
  }
}

.c-logo-color-primary{
  fill: $primary;
}

.c-logo-color-secondary{
  fill: $secondary;
}

.c-logo-color-tertiary{
  fill: $tertiary;
}