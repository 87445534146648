a.c-card{
  text-decoration: none !important;
  flex:1;
  p{
    color: $dark;
    font-weight:normal;
  }
}

.c-card-unit{
  padding: map-get($spacing, md);
  text-align: center;
}

.c-card-color-primary{
  border-bottom: 10px solid $primary;
}

.c-card-color-secondary{
  border-bottom: 10px solid $secondary;
}

.c-card-color-tertiary{
  border-bottom: 10px solid $tertiary;
}