/*
 * SECTIONS
 */

.c-section{
  width: 100%;
}

.c-section-lightblue{
  background-color: #f9fbfd;
}

.c-section-lightgrey{
  background-color: $light;
}