/*
 * BUTTONS
 */

.c-button{
  display: inline-block;
  overflow: hidden;
  padding: .75em 1em;
  background-color: transparent;
  border-radius: 3px;
  font-weight: bold;
  line-height: normal;
  color: $dark;
  transition: all .1s ease;
  text-align:center;

}

.c-button-info{
  background-color: $info;
  border-color: darken($info, 5%);
  color: #fff;

  &:hover{
    background-color: darken($info, 5%);
  }
}

.c-button-success{
  background-color: $success;
  border-color: darken($success, 5%);
  color: #fff;

  &:hover{
    background-color: darken($success, 5%);
  }
}

.c-button-warning{
  background-color: $warning;
  border-color: darken($warning, 5%);
  color: #fff;

  &:hover{
    background-color: darken($warning, 5%);
  }
}

.c-button-error{
  background-color: $error;
  border-color: darken($error, 5%);
  color: #fff;

  &:hover{
    background-color: darken($error, 5%);
  }
}