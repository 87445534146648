/*
 * HERO
 */

.c-hero {
  position: relative;
  z-index: 2;
  width: 100%;
  background-color: $light;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.c-hero-sideway {
  &:after {
    content: '';
    width: 100%;
    -webkit-transform: skew(0deg, -1deg);
    -moz-transform: skew(0deg, -1deg);
    -ms-transform: skew(0deg, -1deg);
    -o-transform: skew(0deg, -1deg);
    transform: skew(0deg, -1deg);
    display: block;
    height:60px;
    background-color: #FFFFFF;
    position: absolute;
    bottom: -40px;
    left:0;
  }

}

.c-hero-large {
  min-height: 480px;

  @include media-query(md) {
    min-height: 720px;
  }
}

.c-hero-medium {
  min-height: 320px;

  @include media-query(md) {
    min-height: 480px;
  }
}

.c-hero-nav {
  margin-top: -130px;

  .c-hero-title {
    margin-top: 130px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-top: 170px;
    }
  }
}

.c-hero-nav-bis {
  margin-top: -190px;

  .c-hero-title {
    margin-top: 140px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-top: 220px;
    }
  }
}

.c-hero-title {
  padding: map-get($spacing, lg) 0;

  h1 {
    text-align: center;
    color: white;
    text-shadow: 1px 1px 9px rgba(black, .9);
  }
}

.c-hero-title-home {
  margin-bottom: 120px;
}