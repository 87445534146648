.c-unit-overview{
  position: relative;
  z-index: 2;
  margin-top: -120px;
}

.c-unit{
  a{
    text-decoration: underline;
    font-weight:bold;
    &:hover{
      .primary &{
        color:$primary;
        transition: all 0.3s ease-out;
      }
      .secondary &{
        color:$secondary;
        transition: all 0.3s ease-out;
      }
      .tertiary &{
        color:$tertiary;
        transition: all 0.3s ease-out;
      }
    }
  }
}