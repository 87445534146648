.c-social-icons {
    bottom: 10px;
    z-index: 3;
    position: fixed;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: none;
    height: 170px;

    @include media-query(md) {
        display: block;
    }

    ul {
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
        background-color: #FFFFFF;
        li {
            list-style-type: none;
            position: relative;
            display: block;
            a {
                display: block;
                width: 50px;
                height: 50px;
                padding: 10px;
                margin-bottom: 10px;
                background-color: #FFFFFF;
                svg {
                    fill: $primary;
                }
                &:hover {
                    background-color: #9CA7AE;
                    svg {
                        fill: #ffffff;
                        transition: all 0.2s ease-out;

                    }
                }
            }
            .c-icon {
                width: 20px;
                height: 20px;
            }
            svg {
                margin: 0;
                padding: 0;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.c-social-icons-mobile {
    display: block;
    @include media-query(md) {
        display: none;
    }
    ul {
        li {
            list-style-type: none;
            display: inline-block;
            margin: 0 5px;
        }
    }
}