.c-newsletter {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: map-get($spacing, md) 0 map-get($spacing, lg) 0;

  .c-newsletter-content {
    min-height: 320px;
    width: 100%;
    background: $primary-gradient;
    display: flex;
    align-items: center;
    &:after {
      content: '';
      min-height: inherit;
      font-size: 0;
      display: block;
    }

    label, .error, .success {
      color: #FFFFFF;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    height: 75px;
    width: 100%;
    display: block;
    background: #FFFFFF;
    -webkit-transform: skew(0deg, -1deg);
    -moz-transform: skew(0deg, -1deg);
    -ms-transform: skew(0deg, -1deg);
    -o-transform: skew(0deg, -1deg);
    transform: skew(0deg, -1deg);
    z-index: 1;
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: -50px;
    left: 0;
    height: 75px;
    width: 100%;
    background: #FFFFFF;
    -webkit-transform: skew(0deg, 1deg);
    -moz-transform: skew(0deg, 1deg);
    -ms-transform: skew(0deg, 1deg);
    -o-transform: skew(0deg, 1deg);
    transform: skew(0deg, 1deg);
    z-index: 1;
  }
}

.c-newsletter-title {
  h2 {
    color: white;
  }
}