.c-slider {
    width: 100%;
    position: relative;

    .rsContent {

        position: relative;
        overflow: hidden;

        .rs-infoBlock {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            text-align: center;
            top: 0;

            .rs-logo {
                display: block;
                align-items: center;
                justify-content: center;
                margin: 0 auto 1em;
                padding: 20px;
                background-color: white;
                max-width: 150px;

                img {
                    display: block;
                    width: 100%;
                }
            }

            h1, h2, h3, h4, h5, h6, p {
                color: $light;
                text-shadow: 1px 1px 9px rgba(black, .9);
            }

            p {
                font-weight: 700;
            }
        }
    }
}

.c-slider-label{
    position: absolute;
    bottom: 4px;
    left: 4px;
    background-color: white;
    padding: 4px;
    display: inline-block;

    p{
        margin-bottom: 0;
        font-weight: 700;
        font-size: .85em;
    }
}

