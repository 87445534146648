/*
 * BASE
 */

html{
  width: 100%;
  height: 100%;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $base-color;
  @include font-smoothing(on);
}

body{
  width: 100%;
  height: 100%;
}

h3, h2{
  a{
    font-weight:normal !important;
  }
}