/*
 * SPACING
 */

.u-margin{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      margin: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          margin: #{map-get($spacing, $m)};
        }
      }
    }
  }
}

.u-margin-x{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      margin-left: #{map-get($spacing, $m)};
      margin-right: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          margin-left: #{map-get($spacing, $m)};
          margin-right: #{map-get($spacing, $m)};
        }
      }
    }
  }
}

.u-margin-y{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      margin-top: #{map-get($spacing, $m)};
      margin-bottom: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          margin-top: #{map-get($spacing, $m)};
          margin-bottom: #{map-get($spacing, $m)};
        }
      }
    }
  }
}

.u-margin-top{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      margin-top: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          margin-top: #{map-get($spacing, $m)};
        }
      }
    }
  }
}

.u-margin-bottom{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      margin-bottom: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          margin-bottom: #{map-get($spacing, $m)};
        }
      }
    }
  }
}

.u-margin-left{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      margin-left: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          margin-left: #{map-get($spacing, $m)};
        }
      }
    }
  }
}

.u-margin-right{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      margin-right: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          margin-right: #{map-get($spacing, $m)};
        }
      }
    }
  }
}

.u-padding{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      padding: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          padding: #{map-get($spacing, $m)};
        }
      }
    }
  }
}

.u-padding-x{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      padding-left: #{map-get($spacing, $m)};
      padding-right: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          padding-left: #{map-get($spacing, $m)};
          padding-right: #{map-get($spacing, $m)};
        }
      }
    }
  }
}

.u-padding-y{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      padding-top: #{map-get($spacing, $m)};
      padding-bottom: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          padding-top: #{map-get($spacing, $m)};
          padding-bottom: #{map-get($spacing, $m)};
        }
      }
    }
  }
}

.u-padding-top{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      padding-top: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          padding-top: #{map-get($spacing, $m)};
        }
      }
    }
  }
}

.u-padding-bottom{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      padding-bottom: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          padding-bottom: #{map-get($spacing, $m)};
        }
      }
    }
  }
}

.u-padding-left{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      padding-left: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          padding-left: #{map-get($spacing, $m)};
        }
      }
    }
  }
}

.u-padding-right{
  @each $m, $val in map-keys($spacing){
    &-#{$m}{
      padding-right: #{map-get($spacing, $m)};
    }
  }

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      @each $m, $val in map-keys($spacing){
        &-#{$m}-#{$bp}{
          padding-right: #{map-get($spacing, $m)};
        }
      }
    }
  }
}