/*
 * TEXT
 */

.u-text-container{
  > *:last-child{
    margin-bottom: 0;
  }

  li{
    list-style-type: disc;
    list-style-position: inside;
    text-indent: -23px;
    padding-left: 23px;
    
    ul{
      li{
        list-style-type: circle;
      }
    }
  }

  a{
    text-decoration: underline;
    font-weight:bold;
    .primary &{
      color:$primary;
      transition: all 0.3s ease-out;
    }
    .secondary &{
      color:$secondary;
      transition: all 0.3s ease-out;
    }
    .tertiary &{
      color:$tertiary;
      transition: all 0.3s ease-out;
    }
  }
}

.u-text-left{
  text-align: left;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        text-align: left;
      }
    }
  }
}

.u-text-right{
  text-align: right;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        text-align: right;
      }
    }
  }
}

.u-text-center{
  text-align: center;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        text-align: center;
      }
    }
  }
}