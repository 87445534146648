/*
 * FOOTER
 */

.c-footer {
  width: 100%;
  background-color: $light;
  padding: map-get($spacing, lg) 0 map-get($spacing, md) 0;
}

.c-footer-catalog {
  .primary & {
    background-color: $primary;
  }
  .secondary & {
    background-color: $secondary;
  }
  .tertiary & {
    background-color: $tertiary;
  }
  .quarternary &{
    background-color: #0A0A0A;
  }
  h3 {
    color: #ffffff;
  }
}