/*
 * BODY WRAP
 */

.o-body {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;

    &.nav-mobile-is-active {
        overflow: hidden;
    }
}