.c-subnav{
  z-index:2;
  position:relative;
  ul{
    border-bottom: 1px solid $light;

    li{
      list-style-type:none;
      text-align:center;
      a{
        position:relative;
        &:after{
          content: '';
          display: block;
          margin: 0 auto;
          padding-bottom:10px;
          width: 0%;
          height: 0%;
          opacity: 0;

          .primary & {
             border-bottom: 2.5px solid $primary;
           }

          .secondary & {
            border-bottom: 2.5px solid $secondary;
          }

          .tertiary & {
            border-bottom: 2.5px solid $tertiary;
          }

          -webkit-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
        &.is-active{
          font-weight:bold;
          &:after{
            content: '';
            display: block;
            margin: 0 auto;
            width: 100%;
            height: auto;
            opacity: 1;
            padding-bottom:10px;
          }
        }
        &:hover{
          &:after{
            content: '';
            display: block;
            margin: 0 auto;
            width: 100%;
            height: auto;
            opacity: 1;
            padding-bottom:10px;
          }
        }
      }
    }
  }
}