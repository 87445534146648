/*
 * ALERTS
 */

.c-alert{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: .75em 1em;
  background-color: $light;
  border: 1px solid darken($light, 10%);
}

.c-alert-info{
  background-color: $info;
  border-color: darken($info, 10%);
  color: #fff;
}

.c-alert-success{
  background-color: $success;
  border-color: darken($success, 10%);
  color: #fff;
}

.c-alert-warning{
  background-color: $warning;
  border-color: darken($warning, 10%);
  color: #fff;
}

.c-alert-error{
  background-color: $error;
  border-color: darken($error, 10%);
  color: #fff;
}

.c-alert-close{
  display: block;
  flex: 0 0 auto;
  margin-left: 1em;
  width: 22px;
  height: 22px;
  font-size: rem(22);
  font-weight: bold;
  text-align: center;
  line-height: 22px;
  transition: transform .1s ease;

  &:hover{
    text-decoration: none;
  }

  &:active{
    transform: translateY(1px);
  }
}