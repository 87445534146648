/*
 * PARAGRAPHS
 */

p{
  margin-bottom: 1.125em;
}

.e-p-small{
  font-size:0.7em;
}

