/*
 * FLEXBOX
 */

// DISPLAY
.o-flex{
  display: flex;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        display: flex;
      }
    }
  }
}

.o-inline-flex{
  display: inline-flex;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        display: inline-flex;
      }
    }
  }
}

// GROW/SHRINK
.o-flex-grow{
  flex: 1 0 auto;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex: 1 0 auto;
      }
    }
  }
}

.o-flex-shrink{
  flex: 0 1 auto;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex: 0 1 auto;
      }
    }
  }
}

.o-flex-auto{
  flex: 1 1 auto;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex: 1 1 auto;
      }
    }
  }
}

// WRAP
.o-flex-wrap{
  flex-wrap: wrap;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex-wrap: wrap;
      }
    }
  }
}

.o-flex-wrap-reverse{
  flex-wrap: wrap-reverse;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex-wrap: wrap-reverse;
      }
    }
  }
}

.o-flex-nowrap{
  flex-wrap: nowrap;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex-wrap: nowrap;
      }
    }
  }
}

// DIRECTION
.o-flex-row{
  flex-direction: row;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex-direction: row;
      }
    }
  }
}

.o-flex-row-reverse{
  flex-direction: row-reverse;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex-direction: row-reverse;
      }
    }
  }
}

.o-flex-column{
  flex-direction: column;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex-direction: column;
      }
    }
  }
}

.o-flex-column-reverse{
  flex-direction: column-reverse;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex-direction: column-reverse;
      }
    }
  }
}

// JUSTIFY
.o-flex-justify-content-start{
  justify-content: flex-start;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        justify-content: flex-start;
      }
    }
  }
}

.o-flex-justify-content-end{
  justify-content: flex-end;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        justify-content: flex-end;
      }
    }
  }
}

.o-flex-justify-content-center{
  justify-content: center;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        justify-content: center;
      }
    }
  }
}

.o-flex-justify-content-around{
  justify-content: space-around;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        justify-content: space-around;
      }
    }
  }
}

.o-flex-justify-content-between{
  justify-content: space-between;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        justify-content: space-between;
      }
    }
  }
}

.o-flex-justify-content-evenly{
  justify-content: space-evenly;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        justify-content: space-evenly;
      }
    }
  }
}

// ALIGN CONTENT
.o-flex-align-content-start{
  align-content: flex-start;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        align-content: flex-start;
      }
    }
  }
}

.o-flex-align-content-end{
  align-content: flex-end;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        align-content: flex-end;
      }
    }
  }
}

.o-flex-align-content-center{
  align-content: center;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        align-content: center;
      }
    }
  }
}

.o-flex-align-content-stretch{
  align-content: stretch;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        align-content: stretch;
      }
    }
  }
}

.o-flex-align-content-around{
  align-content: space-around;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        align-content: space-around;
      }
    }
  }
}

.o-flex-align-content-between{
  align-content: space-between;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        align-content: space-between;
      }
    }
  }
}

// ALIGN ITEMS
.o-flex-align-items-start{
  align-items: flex-start;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        align-items: flex-start;
      }
    }
  }
}

.o-flex-align-items-end{
  align-items: flex-end;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        align-items: flex-end;
      }
    }
  }
}

.o-flex-align-items-stretch{
  align-items: stretch;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        align-items: stretch;
      }
    }
  }
}

.o-flex-align-items-center{
  align-items: center;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        align-items: center;
      }
    }
  }
}

.o-flex-align-items-baseline{
  align-items: baseline;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        align-items: baseline;
      }
    }
  }
}

// GROW/SHRINK
.o-flex-0-0-auto{
  flex: 0 0 auto;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex: 0 0 auto;
      }
    }
  }
}

.o-flex-1-1-auto{
  flex: 1 1 auto;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex: 1 1 auto;
      }
    }
  }
}

.o-flex-0-1-auto{
  flex: 0 1 auto;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex: 0 1 auto;
      }
    }
  }
}

.o-flex-1-0-auto{
  flex: 1 0 auto;

  @each $bp in map-keys($breakpoints){
    @include media-query($bp){
      &-#{$bp}{
        flex: 1 0 auto;
      }
    }
  }
}