/*
 * MAIN
 */

.c-nav-primary {
  width: 100%;
  height: 80px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
  }



  li {
    list-style-type: none;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    height: 80px;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: map-get($spacing, md);
    font-size: 1.25rem;
    font-family: 'Bebas', Helvetica, sans-serif;
    color: white;
    transition: background-color .25s ease;
  }

  .primary & {
    background-image: $primary-gradient;

    a.is-active,
    a:hover {
      background-color: $primary;
    }
  }

  .secondary & {
    background-image: $secondary-gradient;

    a.is-active,
    a:hover {
      background-color: $secondary;
    }
  }

  .tertiary & {
    background-image: $tertiary-gradient;

    a.is-active,
    a:hover {
      background-color: $tertiary;
    }
  }

  .quarternary & {
    background: #0A0A0A;

    a.is-active,
    a:hover {
      background-color: $quarternary;
    }
  }
}

.c-nav-languages{
  display:none !important;
}

.c-nav-primary-bis {
  width: 100%;
  height: 60px;

  ul:first-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
  }

  li {
    list-style-type: none;
    border-right: 1px solid transparent;
    position: relative;
  }

  a {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 60px;
    padding: map-get($spacing, sm) map-get($spacing, md);
    font-family: 'Bebas', Helvetica, sans-serif;
    font-size: 1.125rem;
    color: #fff;
    transition: all .2s ease;

    &.is-active,
    &:hover {
      .primary & {
        background-color: darken($primary, 5%);
      }

      .secondary & {
        background-color: darken($secondary, 5%);
      }

      .tertiary & {
        background-color: darken($tertiary, 5%);
      }
      .quarternary & {
        background-color: darken($quarternary, 5%);
      }
    }
  }

  .primary & {
    background-color: $primary;
  }

  .secondary & {
    background-color: $secondary;
  }

  .tertiary & {
    background-color: $tertiary;
  }
  .quarternary & {
    background-color: $quarternary;
  }
  .c-icon {
    padding: 0 10px;
    fill: #FFFFFF;
    width: 30px;
    height: 30px;
  }
}

.c-nav-primary-bis-subnav {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  padding: 0;
  margin: 0;
  &.is-active {
    display: block;
  }
  .primary & {
    background-color: $primary;
  }
  .secondary & {
    background-color: $secondary;
  }
  .tertiary & {
    background-color: $tertiary;
  }
  .quarternary & {
    background-color: $quarternary;
  }
  &:hover {
    display: block;
  }
  li {
    a {
      display: block !important;
      width: 100%;
      height: auto;
      padding: 0.75rem 1.5rem !important;
      align-items: center;
    }
  }
}

.c-nav-secondary {
  width: 100%;
  height: 50px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
  }

  li {
    list-style-type: none;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    display: inline-flex;
    padding: map-get($spacing, xs);
    font-size: .875rem;
    color: white;

    &.is-active {
      font-weight: bold;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.c-nav-footer-primary,
.c-nav-footer-secondary {
  ul {
    margin-bottom: 0;
  }

  li {
    list-style-type: none;
    margin-bottom: .5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    font-family: 'Bebas', Helvetica, sans-serif;
    font-size: 1.25rem;
    transition: all .1s ease;

    &:hover,
    &.is-active {
      color: $dark;
    }
  }
}

.c-nav-mobile {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  @include media-query(md) {
    display: none;
  }

  ul {
    margin-bottom: 0;
  }

  li {
    position: relative;
    width: 100%;
    list-style-type: none;
  }

  a {
    height: 80px;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: map-get($spacing, md);
    font-family: 'Bebas', Helvetica, sans-serif;
    font-size: 1.25rem;
    color: white;
    transition: background-color .25s ease;
  }

  &.c-nav-color-primary {
    a.is-active {
      background-color: $primary;
    }
  }

  &.c-nav-color-secondary {
    a.is-active {
      background-color: $secondary;
    }
  }

  &.c-nav-color-tertiary {
    a.is-active {
      background-color: $tertiary;
    }
  }
  &.c-nav-color-quarternary {
    a.is-active {
      background-color: $quarternary;
    }
  }
}

.c-nav-mobile-button {
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  text-indent: -999rem;

  span {
    display: block;
    width: 20px;
    height: 2px;
    background-color: white;
    position: absolute;
    left: 50%;
    margin-left: -10px;

    &:nth-child(1) {
      top: 33px;
      margin-top: -1px;
      transition: top .1s .1s ease, transform .1s ease;

      .nav-mobile-is-active & {
        top: 50%;
        transform: rotate(45deg);
        transition: top .1s .1s ease, transform .1s .2s ease;
      }
    }

    &:nth-child(2) {
      top: 50%;
      margin-top: -1px;
      transform-origin: center;
      transition: width .1s .2s ease;

      .nav-mobile-is-active & {
        width: 0px;
        transition: width .1s ease;
      }
    }

    &:nth-child(3) {
      bottom: 33px;
      margin-bottom: -1px;
      transition: bottom .1s .1s ease, transform .1s ease;

      .nav-mobile-is-active & {
        bottom: 50%;
        transform: rotate(-45deg);
        transition: bottom .1s .1s ease, transform .1s .2s ease;
      }
    }
  }
}

.c-nav-mobile-list {
  display: flex;
  position: fixed;
  top: 80px;
  left: -100%;
  width: 100%;
  height: calc(100% - 80px);
  background-color: rgba($dark, .95);
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  opacity: 0;
  transition: opacity .3s ease, left 0s .3s;

  .nav-mobile-is-active & {
    left: 0;
    opacity: 1;
    transition: opacity .3s ease;
  }

  a {
    width: 100%;
    height: 48px;
    padding: map-get($spacing, sm);
    justify-content: flex-start;
  }
}

.c-nav-mobile-toggle-subnav {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 48px !important;
  height: 48px !important;
  background-color: rgba(white, 0.1);
  justify-content: center !important;
  align-content: center !important;

  &.is-active {
    background-color: rgba(white, 0.1) !important;
    transform: rotate(180deg);
  }

  + ul {
    background-color: rgba(white, 0.1);
    height: 0;
    margin: 0;
    transform: scale(1, 0);

    &.is-active {
      height: auto;
      transform: scale(1, 1);
    }

    a {
      font-size: 1.15rem;
    }
  }
}

.c-nav-pager {
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  li {
    list-style-type: none;

    &.is-active {
      border: 2px solid $light;
      border-radius: 50%;
    }
  }

  a,
  span {
    display: block;
    font-weight: bold;
    text-align: center;
    line-height: 44px;
    width: 44px;
    height: 44px;
  }

  a {
    transition: all .1s ease;

    &:hover {
      color: $dark;
    }
  }

  span {
    color: $dark;
  }
}

.c-nav-pager-direction a {
  font-size: 1.25rem;
}

.c-nav-categories {
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0;
  }

  li {
    list-style-type: none;
    margin-right: 1px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    display: inline-block;
    overflow: hidden;
    padding: .75em 1em;
    background-color: transparent;
    border-radius: 3px;
    font-weight: bold;
    line-height: normal;
    color: $dark;
    transition: all .1s ease;
    position: relative;
    z-index: 0;

    &.is-active {
      background: $primary-gradient;
      color: #fff;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      background-color: $primary;
      transition: all .25s ease;
    }

    &:hover {
      color: #fff;

      &:after {
        opacity: 1;
      }
    }
  }
}

.c-nav-filter {
  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-iems: flex-start;
  }

  li {
    list-style-type: none;
  }
}