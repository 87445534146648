.c-button{
  display: inline-block;
  overflow: hidden;
  padding: .75em 1em;
  background-color: transparent;
  border-radius: 3px;
  font-weight: bold;
  line-height: normal;
  color: $dark;
  transition: all .1s ease;


}

.c-button-primary, #mCCButton{
  position: relative;
  z-index: 0;
  background: $primary-gradient;
  color: #fff !important;
  text-decoration: none !important;

  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: $primary;
    transition: all .25s ease;
  }

  &:hover{
    &:after{
      opacity: 1;
    }
  }
}

.c-button-secondary{
  position: relative;
  z-index: 0;
  background: $secondary-gradient;
  color: #fff !important;
  text-decoration: none !important;

  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: $secondary;
    transition: all .25s ease;
  }

  &:hover{
    &:after{
      opacity: 1;
    }
  }
}

.c-button-tertiary{
  position: relative;
  z-index: 0;
  background: $tertiary-gradient;
  color: #fff !important;
  text-decoration: none !important;

  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: $tertiary;
    transition: all .25s ease;
  }

  &:hover{
    &:after{
      opacity: 1;
    }
  }
}

.c-button-quarternary{
  position: relative;
  z-index: 0;
  background: $quarternary-gradient;
  color: #fff !important;
  text-decoration: none !important;

  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    transition: all .25s ease;
    color:#FFFFFF;
    background-color: $quarternary;
  }

  &:hover{
    &:after{
      opacity: 1;
    }
  }
}

.c-button-white{
  background-color: white;
  color: $dark;

  &:hover{
    background-color: darken(white, 5%);
  }
}

.c-button-black{
  background-color: black;
  color: #fff;

  &:hover{
    background-color: lighten(black, 5%);
  }
}

.c-button-link{
  font-weight: bold;
  color: $dark;
  border-bottom: 2px solid $dark;
  .primary &{
    color: $primary;
    border-color: $primary;
  }
  .secondary &{
    color: $secondary;
    border-color: $secondary;
  }
  .tertiary &{
    color: $tertiary;
    border-color: $tertiary;
  }
  .quarternary &{
    color: $quarternary;
    border-color: $quarternary;
  }
}