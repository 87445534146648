.c-title-underlined{
  &:after{
    content: '';
    display: block;
    width: 50px;
    height: 5px;
    margin-top: 1rem;

    .primary & {
      background-color: $primary;
    }
    .secondary & {
      background-color: $secondary;
    }
    .tertiary & {
      background-color: $tertiary;
    }
  }
}