/*
 * MIXINS
 */

// MEDIA QUERIES

@mixin media-query($breakpoint, $query1: min, $query2: width){
  $query: map-get($breakpoints, $breakpoint);

  @if $query != null {
    @media (#{$query1}-#{$query2}: #{$query}) {
        @content;
    }
  } @else {
    @media (#{$query1}-#{$query2}: $breakpoint) {
        @content;
    }
  }
}

// LAST #
@mixin last($i: 1){
  &:nth-last-child(-n+#{$i}){
    @content;
  }
}

// FIRST #
@mixin first($i: 1){
  &:nth-child(-n+#{$i}){
    @content;
  }
}

// EVERY #
@mixin every($i: 1){
  &:nth-child(#{$i}n+1){
    @content;
  }
}

// ODD #
@mixin odd(){
  &:nth-child(odd){
    @content;
  }
}

// EVEN #
@mixin even(){
  &:nth-child(even){
    @content;
  }
}

// FONT-SMOOTHING
@mixin font-smoothing($value: on){
  @if $value == on {
    -webkit-font-smoothing:   antialiased;
    -moz-osx-font-smoothing:  grayscale;
  }

  @else {
    -webkit-font-smoothing:   subpixel-antialiased;
    -moz-osx-font-smoothing:  auto;
  }
}

// FONT-FACE
@mixin fontFace($family,$src,$weight: normal,$style: normal){
  @font-face {
    font-family: $family;
    src: url('#{$src}.eot');
    src: url('#{$src}.eot?#iefix') format('embedded-opentype'),
    url('#{$src}.woff') format('woff'),
    url('#{$src}.ttf') format('truetype'),
    url('#{$src}.svg##{$family}') format('svg');
    font-style: $style;
    font-weight: $weight;
  }
}

// FLUID FONT-SIZE
@mixin interpolate($properties, $min-screen, $max-screen, $min-value, $max-value){
  &{
    @each $property in $properties {
      #{$property}: $min-value;
    }

    @media screen and (min-width: $min-screen) {
      @each $property in $properties {
        #{$property}: calc-interpolation($min-screen, $min-value, $max-screen, $max-value);
      }
    }

    @media screen and (min-width: $max-screen) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}