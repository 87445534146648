/*
 * MAIN
 */

.c-main{
  width: 100%;
  padding: map-get($spacing, lg) 0;

  @include media-query(md){
    padding: map-get($spacing, xl) 0;
  }
}

