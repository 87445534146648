.c-content-product {
  a {
    height: 100%;
  }
}

.c-content-training {
  svg {
    fill: $primary;
  }

  .u-text-container {
    a {
      text-decoration: none;
      color: $dark;
      font-weight: normal;
    }
    .c-button{
      font-weight: bold;
    }
  }
}
