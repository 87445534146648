.c-block{
  margin-bottom: map-get($spacing, lg);

}

.c-block-intro{
  font-size: 1.125rem;
  text-align: center;
  color: $dark;
}

.c-block-text{
  img{
    display:inline-block;
  }
  a{
    font-weight:bold;
    text-decoration: underline;
    .primary &{
      color: $primary;
    }
    .secondary &{
      color: $secondary;
    }
    .tertiary &{
      color: $tertiary;
    }
    &:hover{
      color: $dark;
      transition: all ease-out 0.3s;
    }
  }
}