/*
 * SETTINGS
 */

// GRID
$columns: 12;
$gutter: 24/16*1rem;

$breakpoints: (
        xs: 480/16*1rem,
        sm: 640/16*1rem,
        md: 960/16*1rem,
        lg: 1024/16*1rem,
        xl: 1140/16*1rem,
);

// SPACING
$spacing: (
        auto: auto,
        none: 0/16*1rem,
        xs: 6/16*1rem,
        sm: 12/16*1rem,
        md: 24/16*1rem,
        lg: 48/16*1rem,
        xl: 96/16*1rem
);

// COLORS
$info: #2196f3;
$success: #4caf50;
$warning: #ffc107;
$error: #f44336;

$dark: #43484b;
$medium: #9ca7ae;
$light: #efefef;

$link: #2196f3;

$primary: #009ae0;
$secondary: #eb6020;
$tertiary: #00944d;
$quarternary: #DEBA00;

$primary-gradient: linear-gradient(to bottom, #009ae0, #056ec1);
$secondary-gradient: linear-gradient(to bottom, #eb6020, #d41217);
$tertiary-gradient: linear-gradient(to bottom, #00944d, #07542f);
$quarternary-gradient: linear-gradient(to bottom, #deba00, #ab8400);

// FONTS
@font-face {
  font-family: 'Bebas';
  src: url('../fonts/bebasneue-regular-webfont.woff2') format('woff2'),
  url('../fonts/bebasneue-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// TYPOGRAPHY
$base-font-family: 'Open Sans', Helvetica, sans-serif;
$base-font-size: 1rem;
$base-font-weight: normal;
$base-line-height: 1.625;
$base-color: $dark;

$h1-font-family: 'Bebas', Helvetica, sans-serif;
$h1-font-size: 2.75rem;
$h1-font-weight: normal;
$h1-line-height: 1.375;
$h1-color: $dark;

$h2-font-family: 'Bebas', Helvetica, sans-serif;
$h2-font-size: 2rem;
$h2-font-weight: normal;
$h2-line-height: 1.375;
$h2-color: $dark;

$h3-font-family: 'Bebas', Helvetica, sans-serif;
$h3-font-size: 1.5rem;
$h3-font-weight: normal;
$h3-line-height: 1.375;
$h3-color: $dark;

$h4-font-family: 'Bebas', Helvetica, sans-serif;
$h4-font-size: 1.125rem;
$h4-font-weight: normal;
$h4-line-height: 1.375;
$h4-color: $dark;

$h5-font-family: 'Bebas', Helvetica, sans-serif;
$h5-font-size: 1rem;
$h5-font-weight: normal;
$h5-line-height: 1.375;
$h5-color: $dark;

$h6-font-family: 'Bebas', Helvetica, sans-serif;
$h6-font-size: .875rem;
$h6-font-weight: normal;
$h6-line-height: 1.375;
$h6-color: $dark;