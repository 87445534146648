/*
 * HEADINGS
 */

h1{
  font-family: $h1-font-family;
  font-size: $h1-font-size;
  font-weight: $h1-font-weight;
  line-height: $h1-line-height;
  color: $h1-color;
  margin-bottom: .625em;
}

h2{
  font-family: $h2-font-family;
  font-size: $h2-font-size;
  font-weight: $h2-font-weight;
  line-height: $h2-line-height;
  color: $h2-color;
  margin-bottom: .625em;
}

h3{
  font-family: $h3-font-family;
  font-size: $h3-font-size;
  font-weight: $h3-font-weight;
  line-height: $h3-line-height;
  color: $h3-color;
  margin-bottom: .625em;
}

h4{
  font-family: $h4-font-family;
  font-size: $h4-font-size;
  font-weight: $h4-font-weight;
  line-height: $h4-line-height;
  color: $h4-color;
  margin-bottom: .625em;
}

h5{
  font-family: $h5-font-family;
  font-size: $h5-font-size;
  font-weight: $h5-font-weight;
  line-height: $h5-line-height;
  color: $h5-color;
  margin-bottom: .625em;
}

h6{
  font-family: $h6-font-family;
  font-size: $h6-font-size;
  font-weight: $h6-font-weight;
  line-height: $h6-line-height;
  color: $h6-color;
  margin-bottom: .625em;
}